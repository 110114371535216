<div class="modal-header">{{title}}</div>
<div class="modal-body scrollable-modal">
    <p class="alert alert-primary" role="alert">
        Upload and choose a new cover image. Press Save to upload and override the cover.
    </p>
    <app-cover-image-chooser [(imageUrls)]="imageUrls" (imageSelected)="updateSelectedIndex($event)" (selectedBase64Url)="updateSelectedImage($event)" [showReset]="chapter.coverImageLocked" (resetClicked)="handleReset()"></app-cover-image-chooser>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-primary" (click)="save()" [disabled]="loading">Save</button>
</div>