<div class="badge">
    <!-- Put a person image container here -->
    <div class="img">

    </div>
    <div class="">
        <ng-content select="[name]"></ng-content>
        <div style="font-size: 12px">
            <ng-content select="[role]"></ng-content>
        </div>
    </div>
</div>
